<template>
  <div
    class="modal right fade"
    id="modalAddEmployee"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Karyawan Payroll</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Departemen : </label>
              <Select2
                v-model="formDetail.id_divisi"
                :options="optionDepartement"
                :settings="{ allowClear: true }"
                :disabled="formData.id_divisi"
                placeholder="Pilih Departemen"
                @change="formChange('id_divisi', $event, 'item')"
                @select="formChange('id_divisi', $event, 'item')"
                :class="{
                  'is-invalid': formErrorDetail && formErrorDetail.id_divisi,
                }"
              />
            </div>

            <div class="form-group">
              <label>Jabatan : </label>
              <Select2
                v-model="formDetail.id_jabatan"
                :settings="{ allowClear: true }"
                :options="optionPosition"
                placeholder="Pilih Jabatan"
                @change="formChange('id_jabatan', $event, 'item')"
                @select="formChange('id_jabatan', $event, 'item')"
                :class="{
                  'is-invalid': formErrorDetail && formErrorDetail.id_jabatan,
                }"
              />
            </div>
            <div class="form-group">
              <label>Nama Karyawan : </label>
              <Select2
                v-model="formDetail.id_karyawan"
                :options="optionEmployee"
                placeholder="Masukan Nama Karyawan"
                :settings="{ templateResult: resultEmployee }"
                @change="formChange('id_karyawan', $event, 'item')"
                @select="formChange('id_karyawan', $event, 'item')"
                :class="{
                  'is-invalid': formErrorDetail && formErrorDetail.id_karyawan,
                }"
              />
            </div>
            <div class="alert alert-danger">
              Masukkan nilai item penggajian di bawah sesuai dengan kebutuhan
              anda sebelum menyimpan data
            </div>
            <div
              class="row align-items-center mt-5"
              v-if="listPayrollItem.pemasukan.length > 0"
            >
              <div class="col-6">
                <div class="title">Pemasukan</div>
              </div>
              <div class="col-6 text-right">
                <button
                  type="button"
                  @click="getPreviousData('bonus')"
                  class="btn-add"
                  :disabled="onGetData || !formDetail.id_karyawan"
                  :style="{
                    opacity: onGetData || !formDetail.id_karyawan ? '0.5' : '1',
                  }"
                >
                  Ambil data
                </button>
              </div>
            </div>
            <hr />
            <div class="row">
              <div
                class="col-6"
                v-for="(value, index) in listPayrollItem.pemasukan"
                :key="index"
              >
                <div class="form-group">
                  <label class="">{{ value.nama }} :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      :placeholder="`Masukkan ${value.nama}`"
                      v-model.lazy="formDetail.pemasukan[value.id]"
                      v-money="configMoney"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row align-items-center mt-4"
              v-if="listPayrollItem.potongan.length > 0"
            >
              <div class="col-12">
                <div class="title">Potongan</div>
              </div>
            </div>
            <hr v-if="listPayrollItem.potongan.length > 0" />
            <div class="row">
              <div
                class="col-6"
                v-for="(value, index) in listPayrollItem.potongan"
                :key="index"
              >
                <div class="form-group">
                  <label class="">{{ value.nama }} :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      :placeholder="`Masukkan ${value.nama}`"
                      v-model.lazy="formDetail.potongan[value.id]"
                      v-money="configMoney"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row align-items-center mt-4"
              v-if="listPayrollItem.subsidi.length > 0"
            >
              <div class="col-12">
                <div class="title">Subsidi</div>
              </div>
            </div>
            <hr v-if="listPayrollItem.subsidi.length > 0" />
            <div class="row">
              <div
                class="col-6"
                v-for="(value, index) in listPayrollItem.subsidi"
                :key="index"
              >
                <div class="form-group">
                  <label class="">{{ value.nama }} :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      :placeholder="`Masukkan ${value.nama}`"
                      v-model.lazy="formDetail.subsidi[value.id]"
                      v-money="configMoney"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn-save"
              type="button"
              @click="saveItem"
              style="width: auto; font-size: 14px"
            >
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Bonus</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-payroll">Payroll </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tambah
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button :disabled="isSubmit" class="btn btn-save mr-3">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                {{ enableSync ? "Simpan & Sync" : "Simpan" }}
              </button>
              <!-- <button type="button" class="btn btn-blue">Simpan & Sync</button> -->
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="title">Tambah Bonus</div>
                    <div class="sub-title">
                      Tambah informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Bulan :</label>
                      <Datepicker
                        v-model="formData.bulan"
                        :format="formatMonth"
                        :autoApply="true"
                        placeholder="Pilih Bulan"
                        monthPicker
                        :class="{
                          'is-invalid': formError && formError.bulan,
                        }"
                        @update:modelValue="changeMonth($event, 'bulan')"
                      />
                    </div>

                    <div class="form-group">
                      <label>Tanggal Bayar :</label>
                      <Datepicker
                        :autoApply="true"
                        :closeOnScroll="true"
                        v-model="formData.tanggal_bayar"
                        placeholder="Tanggal Bayar"
                        :format="format"
                        :enableTimePicker="false"
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        :class="{
                          'is-invalid': formError && formError.tanggal_bayar,
                        }"
                        @update:modelValue="changeDate($event, 'tanggal_bayar')"
                      ></Datepicker>
                    </div>
                    <div class="form-group">
                      <label>Akun Kas & Bank :</label>
                      <Select2
                        v-model="formData.akun_perkiraan"
                        :options="optionAkun"
                        :settings="{ templateResult: formatState }"
                        placeholder="Pilih Akun Kas & Bank"
                        @change="formChange('akun_perkiraan')"
                        @select="formChange('akun_perkiraan')"
                        :class="{
                          'is-invalid': formError && formError.akun_perkiraan,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>Tipe Bayar :</label>
                      <Select2
                        v-model="formData.tipe_bayar"
                        :options="optionPaymentType"
                        placeholder="Pilih Tipe Bayar"
                        @change="formChange('tipe_bayar')"
                        @select="formChange('tipe_bayar')"
                        :class="{
                          'is-invalid': formError && formError.tipe_bayar,
                        }"
                      />
                    </div>

                    <div class="form-group">
                      <label>Departemen :</label>
                      <Select2
                        v-model="formData.id_divisi"
                        :options="optionDepartement"
                        :settings="{ allowClear: true }"
                        placeholder="Pilih Departemen"
                        @change="formChange('id_divisi', $event)"
                        @select="formChange('id_divisi', $event)"
                        :class="{
                          'is-invalid': formError && formError.id_divisi,
                        }"
                      />
                    </div>

                    <div class="form-group">
                      <label class="">Keterangan :</label>
                      <textarea
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Keterangan"
                        v-model="formData.keterangan"
                      />
                    </div>
                  </div>
                </div>

                <div class="row align-items-center mt-5">
                  <div class="col-6">
                    <div class="title">Tambah Karyawan Payroll</div>
                    <div class="sub-title">
                      Rincian dari tambah karyawan payroll yang diperlukan
                    </div>
                  </div>

                  <div class="col-6 text-right">
                    <button
                      type="button"
                      class="btn btn-add"
                      @click="insertEmployee"
                    >
                      + Tambah
                    </button>
                  </div>
                </div>
                <hr />
                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tablePayrollBonus"
                  >
                    <thead>
                      <tr>
                        <th style="width: 20%">ID Karyawan</th>
                        <th style="width: 20%">Nama Karyawan</th>
                        <th style="width: 20%">Departemen</th>
                        <th style="width: 15%">Jabatan</th>
                        <th style="width: 15%">Total Bonus</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in listKaryawan"
                        :key="index"
                        @click="showDetail(value, index)"
                      >
                        <td>{{ value.id_karyawan }}</td>
                        <td>{{ value.nama_karyawan }}</td>
                        <td>{{ value.nama_divisi }}</td>
                        <td>{{ value.nama_jabatan }}</td>
                        <td>
                          <div class="d-flex justify-content-between">
                            <div>Rp</div>
                            <div>{{ formatMoney(value.nilai_pemasukan) }}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import {
  get_PayrollPraSave,
  get_PayrollPreviousItem,
  post_PayrollSave,
} from "../../../../actions/payroll";
import { checkRules, cksClient, showAlert, checkApp } from "../../../../helper";
import { get_ListDivisi } from "../../../../actions/master";
import { get_ListPosition } from "../../../../actions/company/posistion";
import { get_KaryawanList } from "../../../../actions/karyawan";
import { VMoney } from "v-money";
import moment from "moment";
import "moment/locale/id";
import { get_AkunList } from "../../../../actions/akun_perkiraan";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      enableSync: checkApp("rawuh") ? true : false,
      optionPaymentType: [
        { id: "bulanan", text: "Bulanan" },
        { id: "non-bulanan", text: "Non-bulanan" },
      ],
      optionDepartement: [],
      optionPosition: [],
      optionEmployee: [],
      optionAkun: [],
      id_company: cksClient().get("_account").id_company,
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //formData
      isSubmit: false,
      formData: {
        id: "",
        tipe_bayar: "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        kategori: "bonus",
        bulan: "bulanan",
        tanggal_bayar: "",
        keterangan: "",
        akun_perkiraan: "",
        status_pembayaran: "belum",
      },
      rules: {
        tipe_bayar: {
          required: true,
        },
        kategori: {
          required: true,
        },
        bulan: {
          required: true,
          isArray: true,
        },
        tanggal_bayar: {
          required: true,
        },
        akun_perkiraan: {
          required: true,
        },
      },
      formError: [],
      listKaryawan: [],
      listPayrollItem: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
      },
      dataPayroll: "",
      //addDetail
      onGetData: false,
      rulesDetail: {
        id_divisi: {
          required: true,
        },
        id_jabatan: {
          required: true,
        },
        id_karyawan: {
          required: true,
        },
      },
      formErrorDetail: [],
      formDetail: {
        id_divisi: "",
        nama_divisi: "",
        id_jabatan: "",
        nama_jabatan: "",
        id_karyawan: "",
        nama_karyawan: "",
        pemasukan: {},
        potongan: {},
        subsidi: {},
        total: 0,
        gaji_diterima: 0,
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
        masa_jabatan: "",
        key: "",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    this.dataPayroll = $("#tablePayrollBonus").DataTable({
      info: false,
    });
    this.tooltip();
  },

  beforeUnmount() {
    if (this.dataPayroll) {
      this.dataPayroll.destroy();
      this.dataPayroll = "";
    }
  },

  created() {
    this.getPrePayroll();
    this.getDivisi();
    this.getPosition();
    this.getEmployee();
    this.getAkun();
  },

  methods: {
    //MASTER
    getPrePayroll() {
      get_PayrollPraSave(
        {
          tipe: "bonus",
          id_company: this.id_company,
        },
        (res) => {
          var { item } = res;
          this.listPayrollItem = item;
        }
      );
    },

    getAkun() {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: 1,
        },
        (res) => {
          var { list } = res;
          var data = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionAkun = data;
        }
      );
    },

    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },

    getPosition() {
      get_ListPosition(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionPosition = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionPosition.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    getEmployee() {
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.optionEmployee = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionEmployee.push({
                id: element.id,
                text: element.nama,
                id_jabatan: element.id_jabatan,
                nama_jabatan: element.jabatan,
                nama_divisi: element.divisi,
                id_divisi: element.id_divisi,
                masa_jabatan: 100,
              });
            }
          }
        }
      );
    },

    formatMonth(date) {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const monthText = month[date.month];
      const year = date.year;

      return `${monthText} ${year}`;
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },

    changeMonth(data, key) {
      this.formData[key].length = data && data.year ? 2 : 0;
      this.formChange(key);
    },

    async formChange(key, val = "", tipe = "") {
      var check;
      if (tipe == "item") {
        if (this.rulesDetail[key]) {
          this.rulesDetail[key].changed = true;
        }
        if (key == "id_karyawan") {
          this.formDetail.id_divisi = val.id_divisi;
          this.formDetail.nama_jabatan = val.nama_jabatan;
          this.formDetail.nama_karyawan = val.text;
          this.formDetail.nama_divisi = val.nama_divisi;
          this.formDetail.id_jabatan = val.id_jabatan;
          this.formDetail.masa_jabatan = val.masa_jabatan;
        }
        check = await checkRules(this.rulesDetail, this.formDetail);
        this.formErrorDetail = check.error;
      } else {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
        check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },

    searchObjectBarang(value) {
      return this.listKaryawan.find((element) => element.id_karyawan == value);
    },

    resultEmployee(event) {
      if (!event.id) {
        return event;
      }
      var check = this.searchObjectBarang(event.id);
      var divisi_check = false;
      var jabatan_check = false;
      if (this.formDetail.id_jabatan && this.formDetail.id_divisi) {
        jabatan_check =
          event.id_jabatan === this.formDetail.id_jabatan
            ? true
            : false;
        divisi_check =
          event.id_divisi === this.formDetail.id_divisi
            ? true
            : false;
        if (!check && divisi_check && jabatan_check) {
          return event.text;
        } else {
          return null;
        }
      } else if (this.formDetail.id_jabatan || this.formDetail.id_divisi) {
        jabatan_check =
          event.id_jabatan === this.formDetail.id_jabatan
            ? true
            : false;
        divisi_check =
          event.id_divisi === this.formDetail.id_divisi
            ? true
            : false;
        if (!check && (divisi_check || jabatan_check)) {
          return event.text;
        } else {
          return null;
        }
      } else {
        if (!check) {
          return event.text;
        } else {
          return null;
        }
      }
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    //employee
    insertEmployee() {
      var nama_divisi = "";
      if (this.formData.id_divisi) {
        var divisi = this.optionDepartement.find(
          (e) => e.id == this.formData.id_divisi
        );
        nama_divisi = divisi.text;
      }
      this.formDetail.id_karyawan = "";
      this.formDetail.key = "";
      this.formDetail.id_divisi = this.formData.id_divisi
        ? this.formData.id_divisi
        : "";
      this.formDetail.nama_divisi = nama_divisi;
      this.formDetail.id_jabatan = "";
      this.formDetail.pemasukan = {};
      this.formDetail.potongan = {};
      this.formDetail.subsidi = {};
      $("#modalAddEmployee").modal("show");
    },

    async saveItem() {
      for (const key in this.rulesDetail) {
        if (Object.hasOwnProperty.call(this.rulesDetail, key)) {
          this.rulesDetail[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesDetail, this.formDetail);
      this.formErrorDetail = check.error;
      var data = await this.countAllItem(this.formDetail);
      if (check.success && data.total > 0) {
        this.dataPayroll.destroy();
        if (this.formDetail.key !== "") {
          this.listKaryawan.splice(this.formDetail.key, 1);
        }
        var dataItem = { ...data };
        this.listKaryawan.push(dataItem);
        $("#modalAddEmployee").modal("hide");
        setTimeout(() => {
          this.dataPayroll = $("#tablePayrollBonus").DataTable({
            info: false,
          });
        }, 500);
      }
    },

    showDetail(value, index) {
      this.formDetail = { ...value };
      this.formDetail.key = index;
      $("#modalAddEmployee").modal("show");
    },

    countAllItem(data) {
      var pemasukan =
        Object.entries(data.pemasukan).length > 0 ? data.pemasukan : {};
      var potongan =
        Object.entries(data.potongan).length > 0 ? data.potongan : {};
      var subsidi = Object.entries(data.subsidi).length > 0 ? data.subsidi : {};

      var nilai_pemasukan = 0;
      var nilai_potongan = 0;
      var nilai_subsidi = 0;
      for (const key in pemasukan) {
        if (Object.hasOwnProperty.call(pemasukan, key)) {
          nilai_pemasukan += pemasukan[key]
            ? parseInt(pemasukan[key].toString().split(".").join(""))
            : 0;
        }
      }
      for (const key in potongan) {
        if (Object.hasOwnProperty.call(potongan, key)) {
          nilai_potongan += potongan[key]
            ? parseInt(potongan[key].toString().split(".").join(""))
            : 0;
        }
      }

      for (const key in subsidi) {
        if (Object.hasOwnProperty.call(subsidi, key)) {
          nilai_subsidi += subsidi[key]
            ? parseInt(subsidi[key].toString().split(".").join(""))
            : 0;
        }
      }
      var total = nilai_pemasukan + nilai_subsidi - nilai_potongan;
      var diterima = nilai_pemasukan - nilai_potongan;
      data.nilai_pemasukan = nilai_pemasukan;
      data.nilai_potongan = nilai_potongan;
      data.nilai_subsidi = nilai_subsidi;
      data.total = total;
      data.gaji_diterima = diterima;
      return data;
    },

    getPreviousData(tipe = "") {
      this.onGetData = true;
      get_PayrollPreviousItem(
        { tipe: tipe, id_karyawan: this.formDetail.id_karyawan },
        (res) => {
          var { pemasukan, potongan, subsidi } = res.item;
          this.onGetData = false;
          console.log(res);
          for (const key in pemasukan) {
            if (Object.hasOwnProperty.call(pemasukan, key)) {
              const element = pemasukan[key];
              if (
                Object.hasOwnProperty.call(
                  this.formDetail.pemasukan,
                  element.id
                )
              ) {
                this.formDetail.pemasukan[element.id] = this.formatMoney(
                  element.nilai
                );
              }
            }
          }

          for (const key in potongan) {
            if (Object.hasOwnProperty.call(potongan, key)) {
              const element = potongan[key];
              if (
                Object.hasOwnProperty.call(this.formDetail.potongan, element.id)
              ) {
                this.formDetail.potongan[element.id] = this.formatMoney(
                  element.nilai
                );
              }
            }
          }

          for (const key in subsidi) {
            if (Object.hasOwnProperty.call(subsidi, key)) {
              const element = subsidi[key];
              if (
                Object.hasOwnProperty.call(this.formDetail.subsidi, element.id)
              ) {
                this.formDetail.subsidi[element.id] = this.formatMoney(
                  element.nilai
                );
              }
            }
          }
        },
        () => {
          this.onGetData = false;
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Data payroll sebelumnya tidak tersedia",
            showConfirmButton: false,
          });
        }
      );
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    postData() {
      this.isSubmit = true;
      var data = { ...this.formData };
      data.bulan = moment(
        data.bulan.year + "-" + (data.bulan.month + 1) + "-01"
      ).format("YYYY-MM");
      data.detail = this.listKaryawan;
      post_PayrollSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data Bonus berhasil dicatat"
              : "Data Bonus berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showConfirmButton: false,
            showCancelButton: true,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && this.listKaryawan.length > 0) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (this.listKaryawan.length === 0) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Tambahkan karyawan terlebih dahulu sebelum menyimpan data",
            showConfirmButton: false,
          });
        }
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal .title {
  font-size: 16px;
}

.modal .btn-add {
  font-size: 12px;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.modal-dialog {
  max-width: 800px;
}

.modal-content {
  height: 100%;
  border-radius: 0px;
}

.modal-content {
  overflow-y: auto;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  padding-right: 40px;
}

.modal-body {
  padding-bottom: 60px;
}
</style>
